import { useState, useEffect } from 'react';
import { Container } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';

const useAureliaRouter = () => {
  const [router, setRouter] = useState(null);

  useEffect(() => {
    const aureliaRouter = Container.instance.get(Router);
    const updateRouter = () => {
      setRouter({
        ...aureliaRouter,
      });
    };

    updateRouter();

    const subscription = aureliaRouter.events.subscribe(
      'router:navigation:success',
      updateRouter
    );

    // Cleanup subscription on component unmount
    return () => subscription.dispose();
  }, []);

  return router;
};

export default useAureliaRouter;
