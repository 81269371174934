

export class FillNewLineValueConverter
{

    toView(str)
    {
        if (!str) {
            return '';
        }

        let count = str.split(/\r\n|\r|\n/).length;

        if (count > 1) {
            return '<br>'.repeat(count - 1);
        }

        return '';
    }

}