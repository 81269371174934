import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  createHeaders,
  filteredColumnList,
  showIfCalculator,
} from '../functions/functions';

import TableRow from './TableRow';
const TableContent = ({
  tableColumns,
  formData,
  setFormData,
  columnsConfig,
  setColumnsShowIfCounter,
  columnsShowIfCounter,
}) => {
  const columnsList = useMemo(() => {
    return createHeaders(tableColumns, columnsConfig);
  }, [tableColumns, columnsConfig]);

  useEffect(() => {
    if (formData.length > 0) {
      formData.forEach((row) => {
        if (columnsList?.length) {
          columnsList.forEach((column) => {
            const { key } = column;
              showIfCalculator(
                key,
                row,
                columnsConfig,
                column,
                setColumnsShowIfCounter
              );
          });
        }
      });
    }
  }, [formData]);

  return (
    <tbody>
      {formData.length > 0 &&
        formData.map((row) => {
          return (
            <TableRow
              key={row.id}
              row={row}
              setFormData={setFormData}
              columnsList={filteredColumnList(
                columnsList,
                columnsShowIfCounter
              )}
              columnsConfig={columnsConfig}
            />
          );
        })}
    </tbody>
  );
};

export default TableContent;
