import React from 'react';
import { getColumnProperties, summaryCounter } from '../../functions/functions';

const SummaryRow = ({ columnsList, formData, columnsConfig }) => {
  let stickyCountFooter = -1;
  return (
    <tr className="summary">
      {columnsList.map(
        (
          {
            ref,
            key,
            label,
            minWidth,
            dynamicItemId,
            required,
            sticky,
            showRoomNumberField,
            hideFooterCount,
            fieldType,
            fieldSubType,
          },
          i
        ) => {
          const { subFields } = getColumnProperties(key, columnsConfig);
          if (sticky) {
            stickyCountFooter = stickyCountFooter + 1;
          }
          return (
            <td
              key={key + dynamicItemId + i}
              style={{
                minWidth: minWidth,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'sticky',
                bottom: '0',
                background: 'white',
                fontSize: '12px',
              }}
              className={`${
                sticky ? 'isSticky isSticky_' + stickyCountFooter : ''
              } `}
            >
              {!hideFooterCount &&
                summaryCounter(
                  formData,
                  key,
                  fieldType,
                  fieldSubType,
                  dynamicItemId,
                  subFields
                )}
            </td>
          );
        }
      )}
    </tr>
  );
};

export default SummaryRow;
