import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../flash/flash-service";
import {WorkflowService} from "../workflow/workflow-service";

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService
)
@customElement('sio-contingent-view')
export class ShipVacancy {

    @bindable object;
    @bindable contextObjectRef;

    client;
    dialogService;
    flash;
    form;

    netContingent = false;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    bind()
    {
        console.log('TEST', this.contextObjectRef);
    }

    submit() {

        let data = this.form.formService.getValue();
        this._isSubmitting = true;

        this.data = null;

        return this.client.post('price/contingent/aggregation', data).then(data => {

            this.data = data.data;
            this._isSubmitting = false;

            return data;
        }).catch(error => {
            this._isSubmitting = false;

            throw error;
        });
    }
}
