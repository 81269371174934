import {bindable, customElement, inject} from "aurelia-framework";
import {UserClient} from "../api/user-client";
import "./range-slider-input.less";

@inject(UserClient)
@customElement('sio-range-slider-input')
export class RangeSliderInput {
    @bindable field;
    @bindable value;
    @bindable max;
    @bindable min;

    constructor() {
    }
}
