import Client from '../api/client';
import { Container } from 'aurelia-framework';

export const getClientInstance = () => {
  const client = Container.instance.get(Client);
  if (!client) {
    return undefined;
  }
  return client;
};

export function useClient() {
  return getClientInstance();
}
