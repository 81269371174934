import { DialogController } from 'aurelia-dialog';
import { inject } from 'aurelia-framework';

@inject(DialogController)
export class PreviewModal {
    filetype = false;

    constructor(dialogController) {
        this.dialogController = dialogController;
    }

    async activate(context) {
        this.file = context.file;
        this.setFiletype(this.file?.extension);
        this.url = this.file.previewUrl
            .replace('$width', '-')
            .replace('$height', '-');
    }

    setFiletype(extension) {
        if(typeof extension != "string") {
            return;
        }
        switch (extension) {
            case 'png':
            case 'jpeg':
            case 'jpg':
            case 'gif':
            case 'svg':
                this.filetype = "img";
                break;
            default:
                this.filetype = "doc";
        }
    }
}
