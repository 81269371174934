import React from 'react';

const TableHeaders = ({columnsList, activeIndex, mouseDown}) => {
  let stickyCount = -1;
  return (
    <thead>
      <tr>
        {/* table headers */}
        {columnsList.map(
          (
            {
              ref,
              key,
              label,
              minWidth,
              dynamicItemId,
              required,
              sticky,
              showRoomNumberField,
            },
            i
          ) => {
            if (sticky) {
              stickyCount = stickyCount + 1;
            }
            return (
              <th
                ref={ref}
                key={dynamicItemId ? key + '_' + dynamicItemId : key}
                id={`form_column_${dynamicItemId ? dynamicItemId : key}`}
                style={{
                  minWidth: minWidth,
                }}
                className={`${
                  sticky ? 'isSticky isSticky_' + stickyCount : ''
                } ${showRoomNumberField ? 'with-kabin-field' : ''}`}
              >
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className={`field ${
                    showRoomNumberField ? 'with-kabin-label' : ''
                  }`}
                >
                  <div
                    className={`f-label ${required ? 'required' : ''} `}
                    style={{ wordBreak: 'break-word' }}
                  >
                    {label}
                  </div>
                  {showRoomNumberField && (
                    <div className="kabin">Kabine Nr</div>
                  )}
                </div>
                <div
                  style={{ height: '100%' }}
                  onMouseDown={() => mouseDown(i)}
                  className={`resize-handle ${
                    activeIndex === i ? 'active' : 'idle'
                  }`}
                />
              </th>
            );
          }
        )}
      </tr>
    </thead>
  );
};

export default TableHeaders;
