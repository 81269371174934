import { useCallback } from 'react';
import { Container } from 'aurelia-framework';
import { UserClient } from '../api/user-client';

let UserClientInstance = Container.instance.get(UserClient);

export const getUserClient = () => {
  if (!UserClientInstance) {
    console.warn('User Client instance not set');
    return false;
  }
  return UserClientInstance;
};

export function useUserClient() {

  const hasRole = async (role) => {
    const data = await UserClientInstance.hasRole(role);
    return data;
  };

  return [UserClientInstance, hasRole];
}
