import React, {useEffect, useState} from "react";
import {
    Button,
    Divider,
    Drawer,
    Icon, IconButton,
    Input,
    InputGroup,
    Loader, Pagination,
    Panel,
    Popover,
    Stack,
    Tag, TagGroup,
    Toggle,
    Whisper
} from "rsuite";
import moment from 'moment-timezone';
import {Container} from "aurelia-framework";
import {ModelLabelService} from "../reference/model-label-service";
import Client from "../api/client";
import {useApiPost} from "../api/use-fetch";
import ReactReferenceDisplay from "../editor/blocks/react-reference-display";
import SearchIcon from "@rsuite/icons/Search";
import ReactChoiceDisplay from "../editor/blocks/react-choice-display";
import {useAureliaI18n} from "../react-hooks/useAureliaI18n";

export const ContingentHover = ({item, onClose}) => {

    let client = Container.instance.get(Client);
    const {t} = useAureliaI18n();

    const {data, loading, updating} = useApiPost('price/contingent/refs', item?.conditions);

    return (
        <Drawer
            open={item != null}
            onClose={onClose}
        >
            <Drawer.Header
                closeButton={false}
                style={{
                    padding: '0px',
                    paddingBottom: '0',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{display: 'flex', paddingBottom: '1rem', padding: '20px'}}
                >
                    <Drawer.Title style={{fontWeight: 'bold'}}>
                        {t('price.contingent-details')}
                    </Drawer.Title>
                </div>
            </Drawer.Header>
            <Drawer.Body style={{padding: 20, backgroundColor: '#f3f3f3'}}>
                {item &&
                    <>
                        {item.disableBooking ? <b>Stopsale<br/></b> : null}
                        <span>Kontingent: {item.contingent}</span><br/>
                        <span>In Benutzung: {item.predictedUsage}</span><br/>
                        <span>Frei: {item.predictedFree}</span><br/>
                        <span>Release-Datum: {moment(item.releaseDate).format('L')}</span><br/><br/>

                        {loading && <Loader center size="lg" backdrop/>}

                        {data && data?.length == 0 && <span>{t('price.no-contingent-usages')}</span>}

                        {data && data.map((element) => {

                            const byState = _.groupBy(element.refs ?? [], function (e) {
                                return e.state;
                            });

                            return <span key={element.contingentPool?.id ?? 'context'}>
                                {element.contingentPool && <p><b>{t('price.contingentPool')}: <ReactReferenceDisplay
                                    reference={element.contingentPool}>{element.title}</ReactReferenceDisplay></b></p>}
                                {
                                    Object.keys(byState).map((state) => {
                                        const refs = byState[state];

                                        const sum = refs.reduce((partialSum, a) => partialSum + a.usage, 0);

                                        return <table key={state}
                                                      className={"table table-sm table-condensed table-super-condensed"}>
                                            <thead>
                                            <tr>
                                                <th>{t('order.bookingStatus.' + state)}</th>
                                                <th>{t('price.usage')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {refs.map((order) => {
                                                return <tr>
                                                    <td>
                                                        <b><ReactReferenceDisplay
                                                            reference={order.order}></ReactReferenceDisplay></b>
                                                    </td>
                                                    <td>
                                                        {order.usage}
                                                    </td>
                                                </tr>;
                                            })}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <th>{t('price.sum')}</th>
                                                <td>{sum}</td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    })
                                }

                    </span>;

                        })}
                    </>
                }
            </Drawer.Body>
        </Drawer>
    );

};

export default ContingentHover;
