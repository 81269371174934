import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {DimensionConfig} from "./dimension-config";

@inject(DialogController, DimensionConfig)
export class ChangePriceDialog {

    changeType = null;
    changeTypes = [
        {
            value: 'relative',
            label: 'sio.field.relative',
        },
        {
            value: 'absolute',
            label: 'sio.field.absolute',
        }
    ];
    changeTypeConfig = {
        label: 'price.changeType',
    };
    factorConfig = {
        label: 'price.changeFactor'
    };
    moneyConfig = {
        label: 'price.money'
    };

    factor = null;
    money = null;

    constructor(dialogController, dimensionConfig) {
        this.dialogController = dialogController;
        this.dimensionConfig = dimensionConfig;
    }

    setChangeType(changeType) {
        this.changeType = changeType;
    }

    activate(context) {
        Object.assign(this, context);
    }

    done() {

        this.dialogController.ok({
            factor: this.factor,
            money: this.money
        });

        return Promise.resolve();
    }
}
