import {DialogController} from "aurelia-dialog";
import {inject} from "aurelia-framework";
import {Client} from '../api/client';
import {UnsavedChanges, UnsavedChangesPrevent} from "../form/unsaved-changes-prevent";
import {UnsavedChangesChecker} from "../form/unsaved-changes-checker";
import * as _ from "lodash";

@inject(DialogController, Client, UnsavedChangesChecker)
export class ProductChoice
{
    constructor(dialogController, client, unsavedChangesChecker)
    {
        this.dialogController = dialogController;
        this.client = client;
        this.unsavedChangesChecker = unsavedChangesChecker;
    }

    activate(context)
    {
        this.data = context.data;
        this.title = context.title;
        this.order = context.order;

        this.originalUnsavedChanges = _.clone(UnsavedChanges.changes);
    }

    async submit(object)
    {
        UnsavedChangesPrevent.cleanChanges();
        const response = await this.client.patch(
            'order/order-item-add/trigger/' + this.order.id,
            object
        );

        if (response.ok && response.error === undefined) {
            this.dialogController.ok("product-choice");
        } else {
            throw new Error(object + ' Response has errors');
        }

    }

    deactivate()
    {
        UnsavedChangesPrevent.cleanChanges();
    }

    async canDeactivate()
    {
        return this.unsavedChangesChecker.canDeactivate(this.originalUnsavedChanges);
    }
}
