import React, { useState, useEffect, useRef, useContext } from 'react';
import { Col, Grid, Row } from 'rsuite';
import { OfferCalendarWrapper } from './offer-calendar-wrapper';
import { OfferSearchForm } from '../components/offer-search-form';
import { OfferPreview } from '../components/offer-preview';
import { OfferResultsCard } from '../components/offer-results-card';
import { CALENDAR_CONTAINER_WIDTH, VIEWS } from '../constants/index';
import { SIOGoogleMap } from '../../../map/google-map/sio-google-map';
import { GMapContext, withGMapOptions } from '../helper/gmap-context';
import './offer-search.less';
import { OrderContext } from '../helper/order-context';
import { OfferPricing } from '../components/offer-pricing';
import { useCallback } from 'react';
import { useMemo } from 'react';

const GRID_COLS = 24;
const SIZING = {
  [VIEWS.DAY]: { calendar: 7 },
  [VIEWS.WEEK]: { calendar: 12, search: 12, map: 12 },
  [VIEWS.LONG_WEEK]: { calendar: 13 },
};

const GMap = withGMapOptions(SIOGoogleMap);

const GoogleMap = React.memo(({ markers, directions }) => {
  return (
    <GMap
      zoom={12}
      height="100%"
      position="static"
      hasInfoView
      markers={markers}
      directions={directions}
    />
  );
});

export const OfferSearch = ({
  items,
  directions,
  firstDate,
  participants,
  fromDate,
  toDate,
  offerStartDate,
  offerEndDate,
  minHotelCategory,
  accommodationDisplay,
  minFare,
}) => {
  const [calendarView, setCalendarView] = useState(VIEWS.LONG_WEEK);
  const [selectedSearchItem, setSelectedSearchItem] = useState(null);
  const [searchItems, setSearchItems] = useState([]);
  const { selectedDate, routingWarnings } = useContext(OrderContext);
  const [unfoldErrors, setUnfoldErrors] = useState(false);
  const [showPurchase, setShowPurchase] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const clearSelectedItem = () => setSelectedSearchItem(null);

  const initialDate = useMemo(() => {
    const getInitialDate = (events) => {
      const sortedEvents = [...events].sort(
        (previous, next) =>
          new Date(previous.fromDate).getTime() -
          new Date(next.fromDate).getTime()
      );
      const earliestEvent = sortedEvents[0];
      const earliestEventStartDate = earliestEvent.fromDate;
      return moment(earliestEventStartDate);
    };
    return items.length > 0 ? getInitialDate(items) : firstDate;
  }, [items, firstDate]);

  const markers = useMemo(
    () =>
      searchItems.map((item) => ({
        position: {
          lat: item.coordinates?.lat,
          lng: item.coordinates?.lng,
        },
        onMarkerSelect: () => {
          setSelectedSearchItem(item);
        },
        component: (
          <OfferResultsCard
            item={item}
            hideTooltip={true}
            showPurchase={showPurchase}
          />
        ),
      })),
    [searchItems]
  );

  const changeCalendarView = useCallback((key) => {
    setCalendarView(VIEWS[key]);
  }, []);

  const handleShowMap = () => {
    if (showMap) {
      changeCalendarView('LONG_WEEK');
    }
    if (!showMap) {
      changeCalendarView('WEEK');
    }
    setShowMap(!showMap);
  };

  return (
    <Grid fluid className="search-view-calendar-container">
      <Row className="search-view-calendar-section">
        <Col xs={GRID_COLS} sm={showMap ? 12 : 18}>
          {initialDate && (
            <OfferCalendarWrapper
              events={items}
              activeView={calendarView}
              initialDate={initialDate}
              selectedDate={selectedDate}
              timeToScroll="08:00:00"
              changeCalendarView={changeCalendarView}
              offerStartDate={offerStartDate}
              offerEndDate={offerEndDate}
            />
          )}
        </Col>

        {showMap ? (
          <Col xs={GRID_COLS} sm={6} className="search-view-full-height">
            <Row className="offer-map-container">
              <GoogleMap markers={markers} directions={directions} />
            </Row>
            <Row>
              <OfferPricing className="offer-price" />
            </Row>
          </Col>
        ) : null}

        <Col
          xs={GRID_COLS}
          sm={6}
          className="search-view-full-height"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {selectedSearchItem ? (
            <OfferPreview
              participants={participants}
              firstDate={firstDate}
              fromDate={fromDate}
              toDate={toDate}
              selectedSearchItem={selectedSearchItem}
              clearSelectedItem={clearSelectedItem}
              className="search-view-full-height"
              offerStartDate={offerStartDate}
              defaultAccommodationDisplay={accommodationDisplay}
            />
          ) : null}
          {!selectedSearchItem &&
            routingWarnings &&
            routingWarnings.length > 0 && (
              <div
                onClick={() => setUnfoldErrors(!unfoldErrors)}
                className="alert alert-warning small"
                role="alert"
                style={{ wordBreak: 'break-word' }}
              >
                {routingWarnings.length} Warnungen bei Routenberechnung:
                <br />
                {unfoldErrors &&
                  routingWarnings.map((routingWarning, index) => {
                    return (
                      <>
                        <span>{routingWarning}</span>
                        <br />
                      </>
                    );
                  })}
              </div>
            )}
          <OfferSearchForm
            firstDate={selectedDate}
            participants={participants}
            selectedSearchItem={selectedSearchItem}
            onResultSelection={setSelectedSearchItem}
            onResultSearch={setSearchItems}
            onResultShowPurchase={setShowPurchase}
            clearSelectedItem={clearSelectedItem}
            minHotelCategory={minHotelCategory}
            minFare={minFare}
            showMap={showMap}
            handleShowMap={handleShowMap}
          />
        </Col>
      </Row>
    </Grid>
  );
};
