import {DialogController} from 'aurelia-dialog';
import {inject} from 'aurelia-framework';

@inject(DialogController)
export class ChargesDialog {

    dialogController;
    loading;
    items = [];

    constructor(dialogController) {
        this.dialogController = dialogController;
    }

    async activate(context) {
        this.charges = context.item.charges;
    }
}
