import {bindable, containerless} from "aurelia-framework";

@containerless
export class DisplayIfFirstParticipant {
    @bindable bucketParticipants;
    @bindable itemParticipants;
    show = false;

    bind()
    {
        this._recalculate();
    }

    bucketParticipantsChanged()
    {
        this._recalculate();
    }

    itemParticipantsChanged()
    {
        this._recalculate();
    }

    _recalculate()
    {
        const itemParticipants = this.itemParticipants;
        const bucketParticipants = this.bucketParticipants;

        if (!bucketParticipants || bucketParticipants.length === 0) {
            this.show = true;
            return;
        }

        this.show = !itemParticipants[0] ||
            (bucketParticipants.filter(participant =>
                itemParticipants[0].id === participant.id &&
                itemParticipants[0].modelId === participant.modelId
            ).length > 0);
    }
}
