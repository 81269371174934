import {bindable, customElement, inject} from 'aurelia-framework';
import {I18N} from "aurelia-i18n"

import "./popover.less";

@inject(I18N)
@customElement('popover')
export class Popover {
    @bindable content;
    @bindable iconClass = "fa fa-circle-question";

    constructor(
        i18n
    ) {
        this.i18n = i18n;
    }

    attached() {
        $(this.popover).popover({
            //Bugfix for popover not dynamically updated
            content: () => {
                return this.i18n.tr(this.content);
            },
            container: 'body',
            placement: 'auto right',
            trigger: 'hover',
            html: true,
            sanitize: false,
        });
    }

    detached() {
        if (this.popover) {
            $(this.popover).popover('destroy')
        }
    }

}
