import {bindable, customElement} from 'aurelia-framework';

import "./hauser-agency-report.less";

@customElement('hauser-agency-report')
export class HauserAgencyReport {

    @bindable year = (new Date).getFullYear();

    table;

    yearConfig = {
        choices: [],
        required: true
    };

    constructor() {
        const year = (new Date).getFullYear();

        for (let y = year - 10; y <= year + 1; y++) {
            this.yearConfig.choices.push({label: y, value: y});
        }
    }

    yearChanged() {
        // force reload
        this.table.configChanged(this.table.config, this.table.config);
    }
}
