import React from 'react';
import { removeOneRow, shouldFormShowField } from '../functions/functions';
import FormField from './FormField';

const TableRow = ({ row, columnsList, setFormData, columnsConfig }) => {
  let stickyCount = -1;
  return (
    <tr>
      {columnsList?.length &&
        columnsList.map((column, index) => {
          const { key, label, dynamicItemId, sticky } = column;
          if (sticky) {
            stickyCount = stickyCount + 1;
          }
          if (key == 'action') {
            return (
              <td key={dynamicItemId ? key + '_' + dynamicItemId : key}>
                <div
                  type="button"
                  className="btn btn-sm"
                  style={{ color: '#c45453' }}
                  onClick={() => {
                    removeOneRow(row?.id, setFormData);
                  }}
                  title="Löschen"
                >
                  <span className="fa fa-xmark"></span>
                </div>
              </td>
            );
          }
          return (
            <td
              key={dynamicItemId ? dynamicItemId : key + '_' + index}
              className={`${sticky ? 'isSticky isSticky_' + stickyCount : ''}`}
            >
              {shouldFormShowField(key, row, columnsConfig, column) ? (
                <FormField
                  row={row}
                  column={column}
                  columnsConfig={columnsConfig}
                  setFormData={setFormData}
                />
              ) : (
                <></>
              )}
            </td>
          );
        })}
    </tr>
  );
};

export default React.memo(TableRow);
