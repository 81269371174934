import {inject} from "aurelia-framework";
import {Executor} from "../executor";
import {FlashService} from "../../../../flash/flash-service";

@inject(
    FlashService
)
export class DocumentsPreviewExecutor extends Executor {
    flash;

    constructor(
        flash
    ) {
        super();

        this.flash = flash;
    }

    match(action, context) {
        return 'documents-preview' === action.type;
    }

    execute(action, cartContext) {
        const {
            order
        } = cartContext;

        const context = {
            id: [order.id],
            modelId: 'order/order'
        };

        return Promise.resolve(context);
    }
}
