import {DialogController} from 'aurelia-dialog';
import {inject} from 'aurelia-framework';
import {Client} from '../../api/client';

@inject(DialogController, Client)
export class OrderTourismMoveDialog {

    dialogController;
    client;

    participants;
    result;

    constructor(dialogController, client) {
        this.dialogController = dialogController;
        this.client = client;
    }

    activate(context) {
        this.participants = context.participants;
        this.result = {
            mode: 'participants-to-new-order',
            selectedParticipants: context.selectedParticipants
        };
    }
}
