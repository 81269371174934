import React from 'react';
import {Popover, Whisper,} from 'rsuite';
import {AureliaConfiguration} from "aurelia-configuration";
import {Container} from "aurelia-framework";

const DebugPopover = ({content}) => {

    let config = Container.instance.get(AureliaConfiguration);

    if (!config.get('debug')) {
        return null;
    }

    return (
        <Whisper
            placement="bottom"
            trigger="hover"
            speaker={
                <Popover style={{maxWidth: '350px', wordBreak: 'break-all'}}>
                    <span dangerouslySetInnerHTML={{__html: content}}></span>
                </Popover>
            }
            enterable
        >
            <i className="fa fa-bug"></i>
        </Whisper>
    );
};

export default DebugPopover;
