import { useState, useEffect } from 'react';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Container } from 'aurelia-framework';

const ea = Container.instance.get(EventAggregator);

export function useAureliaEvent(eventName) {
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const subscription = ea.subscribe(eventName, (data) => {
      setEventData(data);
    });

    return () => subscription.dispose();
  }, [eventName]);

  const publish = (data) => {
    ea.publish(eventName, data);
  };

  return { eventData, publish };
}
