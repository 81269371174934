import {bindingMode, inject} from 'aurelia-framework';
import {UserClient} from '../api/user-client';

function hasPermission(newValue) {
    this.userClient.hasRole(newValue).then(
        hasRole => {
            this.choiceMethod(hasRole);
        },
        () => {
            this.choiceMethod(false);
        }
    );
}

/**
 * Checks if user has permission
 */
@inject(UserClient)
export class PermissionBindingBehavior {

    //Taken from aurelia throttle binding behavior

    constructor(userClient) {
        this.userClient = userClient;
    }

    bind(binding, source) {
        // determine which method to throttle.
        let methodToThrottle = 'updateTarget'; // one-way bindings or interpolation bindings
        if (binding.callSource) {
            methodToThrottle = 'callSource';     // listener and call bindings
        } else if (binding.updateSource && binding.mode === bindingMode.twoWay) {
            methodToThrottle = 'updateSource';   // two-way bindings
        }

        // stash the original method and it's name.
        // note: a generic name like "originalMethod" is not used to avoid collisions
        // with other binding behavior types.
        binding.choiceMethod = binding[methodToThrottle];
        binding.choiceMethod.originalName = methodToThrottle;

        binding.userClient = this.userClient;

        // replace the original method with the throttling version.
        binding[methodToThrottle] = hasPermission;
    }

    unbind(binding, source) {
        // restore the state of the binding.
        let methodToRestore = binding.choiceMethod.originalName;
        binding[methodToRestore] = binding.choiceMethod;

        binding.userClient = null;
    }
}
