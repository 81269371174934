import {bindable, customElement, inject, noView} from "aurelia-framework";
import {render, unmountComponentAtNode} from "react-dom";
import React from "react";
import ContingentDisplay from "./contingentDisplay";

@noView
@inject(Element)
@customElement("contingent-display-aurelia")
export default class ContingentDisplayAurelia {

    @bindable data;

    constructor(container) {
        this.container = container;
    }

    attached() {
        this.render();
    }

    bind() {
        this.render();
    }

    async render() {
        if (this.container) {
            render(<ContingentDisplay
                {...this.data} />, this.container);
        }
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
