import {Executor} from "../executor";
import {OrderTourismMoveDialog} from "../../move-dialog";
import {FlashService} from "../../../../flash/flash-service";
import {DialogService} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {Router} from "aurelia-router";
import * as _ from "lodash";

@inject(
    FlashService,
    DialogService,
    Router
)
export class MoveOrderItemsExecutor extends Executor {
    flash;
    dialogService;
    router;

    constructor(
        flash,
        dialogService,
        router
    ) {
        super();

        this.flash = flash;
        this.dialogService = dialogService;
        this.router = router;
    }

    match(action, context) {
        return 'workflow' === action.type && 'tourism-order/move-order-items' === action.workflowId;
    }

    execute(action, context) {
        const {
            selectedParticipants,
            order,
            participants,
            journeyTitle
        } = context;

        // if (0 === selectedParticipants.length) {
        //     this.flash.error('Bitte erst die Reiseteilnehmern auswählen, die bewegt werden sollen.');
        //     return Promise.resolve(null);
        // }

        const executeMoving = response => {
            switch (response.output.mode) {
                case 'participants-to-new-order':
                    return Promise.resolve({
                        id: order.id,
                        data: {
                            participants: response.output.selectedParticipants.map(id => {
                                return {id: id, modelId: 'tourism/journey-participant'}
                            }),
                        }
                    });

                default:
                    return Promise.reject('Unbekannter Bewegen-Modus');
            }
        };

        return this.dialogService
            .open({
                viewModel: OrderTourismMoveDialog,
                model: {
                    participants: participants,
                    selectedParticipants: selectedParticipants,
                    move: action.move,
                    title: journeyTitle
                }
            })
            .whenClosed(response => {
                if (response.wasCancelled) {
                    return Promise.resolve(null);
                }

                return executeMoving(response);
            });
    }

    afterExecutionCallback(action, context, result)
    {
        if (!result || !result[0].participants || result[0].participants.length === 0) {
            return result;
        }

        // Redirect to newly created order

        const orderId = result[0].participants[0].order.id;

        this.router.navigateToRoute('view', {moduleId: 'order', viewId: 'detail', id: orderId});

        return result;
    }
}
