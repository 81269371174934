import {DialogController} from 'aurelia-dialog';
import {inject} from 'aurelia-framework';

@inject(DialogController)
export class LogDialog {

    dialogController;

    constructor(dialogController) {
        this.dialogController = dialogController;
    }

    async activate(context) {
        this.object = context.object;
    }
}
