import {inject} from 'aurelia-framework';
import {ExecutorRegistry} from "./executor-registry";
import {DefaultExecutor} from "./executors/default.executor";
import {NotWorkflowExecutor} from "./executors/not-workflow.executor";
import {CopyOrderItemsExecutor} from "./executors/copy-order-items.executor";
import {MoveOrderItemsExecutor} from "./executors/move-order-items.executor";
import {DocumentsPreviewExecutor} from "./executors/documents-preview.executor";

@inject(
    DefaultExecutor,
    NotWorkflowExecutor,
    CopyOrderItemsExecutor,
    MoveOrderItemsExecutor,
    DocumentsPreviewExecutor
)
export class ExecutorRegistryFactory {
    defaultExecutor;
    notWorkflowExecutor;
    copyOrderItemsExecutor;
    moveOrderItemsExecutor;
    documentsPreviewExecutor;

    constructor(
        defaultExecutor,
        notWorkflowExecutor,
        copyOrderItemsExecutor,
        moveOrderItemsExecutor,
        documentsPreviewExecutor
    ) {
        this.defaultExecutor = defaultExecutor;
        this.notWorkflowExecutor = notWorkflowExecutor;
        this.copyOrderItemsExecutor = copyOrderItemsExecutor;
        this.moveOrderItemsExecutor = moveOrderItemsExecutor;
        this.documentsPreviewExecutor = documentsPreviewExecutor;
    }

    createRegistry()
    {
        const registry = new ExecutorRegistry();
        registry.registerExecutor(this.defaultExecutor);
        registry.registerExecutor(this.notWorkflowExecutor);
        registry.registerExecutor(this.moveOrderItemsExecutor);
        registry.registerExecutor(this.copyOrderItemsExecutor);
        registry.registerExecutor(this.documentsPreviewExecutor);

        return registry;
    }
}
