import { DialogController } from 'aurelia-dialog';
import { inject } from 'aurelia-framework';
import { Client } from '../../api/client';
import {I18N} from "aurelia-i18n";

@inject(DialogController, Client, I18N)
export class OrderTourismCopyDialog {
    dialogController;
    client;

    move;
    participants;
    result;

    constructor(dialogController, client, i18n) {
        this.dialogController = dialogController;
        this.client = client;
        this.i18n = i18n;

        this.title = this.move
            ? i18n.tr('tourism-order.move')
            : i18n.tr('tourism-order.copy');

    }

    activate(context) {
        this.participants = context.participants;
        this.move = context.move || false;
        this.result = {
            mode: 'participant',
            selectedParticipants: context.selectedParticipants,
        };
    }
}
