import {inject} from "aurelia-framework";
import {Executor} from "../executor";
import {OrderTourismCopyDialog} from "../../copy-dialog";
import {FlashService} from "../../../../flash/flash-service";
import {DialogService} from 'aurelia-dialog';
import * as _ from "lodash";

@inject(
    FlashService,
    DialogService
)
export class CopyOrderItemsExecutor extends Executor {
    flash;
    dialogService;

    constructor(
        flash,
        dialogService
    ) {
        super();

        this.flash = flash;
        this.dialogService = dialogService;
    }

    match(action, context) {
        return 'workflow' === action.type && 'tourism/copy-order-items' === action.workflowId;
    }

    execute(action, context) {
        const {
            selectedItems,
            selectedParticipants,
            participants,
            order,
            journeyTitle
        } = context;

        if (0 === selectedItems.length) {
            return this.flash.error('Bitte erst die Posten auswählen, die kopiert werden sollen.');
        }

        const executeCopying = response => {
            switch (response.output.mode) {
                case 'participant':
                    return Promise.resolve({
                        id: order.id,
                        data: {
                            participants: response.output.selectedParticipants.map(id => {
                                return {id: id, modelId: 'tourism/journey-participant'}
                            }),
                            copyItems: selectedItems.map(id => {
                                return {id: _.castArray(id)[0], modelId: 'order/order-item'};
                            }),
                            move: action.move
                        }
                    });

                default:
                    return Promise.reject('Unbekannter Kopier-Modus');
            }
        };

        let promise;

        if (!action.move) {
            promise = this.dialogService
                .open({
                    viewModel: OrderTourismCopyDialog,
                    model: {
                        participants: participants,
                        selectedParticipants: selectedParticipants,
                        move: action.move,
                        title: journeyTitle
                    }
                })
                .whenClosed(response => {
                    if (response.wasCancelled) {
                        return Promise.resolve(null);
                    }

                    return executeCopying(response);
                });
        } else {
            // If no participants are selected, move to without-participant group without dialog.

            // todo: should we block cart while update of orderitem position is ongoing?

            promise = executeCopying({output: {
                selectedParticipants: selectedParticipants,
                mode: 'participant'
            }});
        }

        return promise;
    }
}
