import React from 'react';
import { getColumnProperties, summaryCounter } from '../../functions/functions';

const CancellationRow = ({
  columnsList,
  formData,
  columnsConfig,
  cancelledItems,
  setCancelledItems,
  orderData,
}) => {
  let stickyCountFooter = -1;
  const cancelOrderItems = orderData?.cancellationHandlingConfig?.map(
    (data) => data?.orderItem
  );
  return (
    <tr className="order-cancellation-items">
      {columnsList.map(
        (
          {
            ref,
            key,
            label,
            minWidth,
            dynamicItemId,
            required,
            sticky,
            showRoomNumberField,
            hideFooterCount,
            fieldType,
            fieldSubType,
            itemIds,
          },
          i
        ) => {
          if (sticky) {
            stickyCountFooter = stickyCountFooter + 1;
          }
          const { subFields } = getColumnProperties(key, columnsConfig);
          return (
            <td
              key={key + dynamicItemId + i}
              style={{
                minWidth: minWidth,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'sticky',
                bottom: '0',
                background: 'white',
                fontSize: '12px',
              }}
              className={`${
                sticky ? 'isSticky isSticky_' + stickyCountFooter : ''
              } ${dynamicItemId}`}
            >
              {key == 'orderItems' &&
              cancelOrderItems?.includes(dynamicItemId) ? (
                <input
                  type={'checkbox'}
                  checked={cancelledItems?.some(
                    (item) => item == dynamicItemId
                  )}
                  className="form-control"
                  onChange={(e) => {
                    const newItems = e.currentTarget.checked
                      ? [...cancelledItems, dynamicItemId]
                      : cancelledItems.filter((item) => item !== dynamicItemId);
                    setCancelledItems(newItems);
                  }}
                />
              ) : key == 'combinedOrderItems' ? (
                <input
                  type={'checkbox'}
                  checked={itemIds?.every((item) =>
                    cancelledItems.includes(item)
                  )}
                  className="form-control"
                  onChange={(e) => {
                    const newItems = e.currentTarget.checked
                      ? [...cancelledItems, ...itemIds]
                      : cancelledItems.filter((item) => !itemIds.includes(item));
                    setCancelledItems(newItems);
                  }}
                />
              ) : (
                <div style={{ backgroundColor: 'white' }}></div>
              )}
            </td>
          );
        }
      )}
    </tr>
  );
};

export default CancellationRow;
