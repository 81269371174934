import {bindable} from "aurelia-framework";
import * as _ from "lodash";


export class ApInvoiceRefDisplay {
    @bindable order;
    @bindable orderItem;

    bind()
    {
        this._recalculate();
    }

    orderChanged()
    {
        this._recalculate();
    }

    orderItemChanged()
    {
        this._recalculate();
    }

    _recalculate() {
        if (!this.order || !this.order.APInvoices || !this.orderItem) {
            this.invoice = null;
            return;
        }

        let invoice = null;

        this.order.APInvoices.forEach(
            _invoice => {
                if (!_invoice.invoiceLines) {
                    return true;
                }

                _invoice.invoiceLines.forEach(
                    line => {
                        let orderItemId = _.castArray(this.orderItem.id);

                        if (orderItemId.indexOf(line.item.id) > -1) {
                            invoice = _invoice;
                            return false;
                        }
                    }
                );

                if (invoice) {
                    return false;
                }
            }
        );

        this.invoice = invoice;
    }

    _idEquals(id1, id2)
    {
        // Sometimes this.orderItem.id is array. We need to cast IDs for comparison purpose.

        let _id1 = _.castArray(id1);
        let _id2 = _.castArray(id2);

        return _.isEqual(_id1, _id2);
    }
}
