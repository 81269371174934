export const selectStyles = {
    control: (base, { isFocused }) => ({
        ...base,
        backgroundColor: 'white',
        borderColor: isFocused ? '#c45453' : 'lightgrey',
        borderRadius: isFocused ? '1px' : 0,
        boxShadow: isFocused ? '0 0 0 2px rgba(196, 84, 83, 0.2)' : 'none',
        fontSize: '12px',
        minHeight: 'inherit',
        maxHeight: '30px',
        ':hover': { borderColor: '#c45453' },
    }),
    singleValue: (base) => ({ ...base, top: '40%' }),
    dropdownIndicator: (base) => ({
        ...base,
        color: 'darkgrey',
        marginTop: '-3px',
    }),
    menu: (base) => ({
        ...base,
        fontSize: '12px',
        borderRadius: 0,
        zIndex: '999',
        overflowX: 'hidden',

    }),
    menuList: (base)=>({
        ...base,
        maxHeight:"200px"
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: '#fff',
        borderRadius: '0px',
    }),
    multiValueLabel: (base) => ({
        ...base,
        borderRadius: '5px',
        color: '#333',
        fontSize: '1rem',
    }),
    multiValueRemove: (base) => ({ ...base, color: '#333' }),
    clearIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => ({ display: 'none' }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        color: '#333',
        backgroundColor: 'transparent',
        padding:'4px 12px',
        fontWeight: isSelected || isFocused ? '700' : '400',
    }),
};
