export class Executor {
    match(action, context)
    {
        throw new Error("Executor.match() must be implemented in subclass");
    }

    execute(action, context)
    {
        throw new Error("Executor.execute() must be implemented in subclass");
    }

    afterExecutionCallback(action, context, result)
    {
        return result;
    }
}
