import React, { memo, useEffect, useState } from "react";
import { ModelLabelService } from "./model-label-service";
import { Container } from "aurelia-framework";

export const ReferenceDisplay = memo(function ReferenceDisplay(props) {
    let modelLabelService = Container.instance.get(ModelLabelService);

    const { reference } = props;

    const [link, setLink] = useState(null);

    useEffect(() => {
        let isMounted = true;

        modelLabelService.getReferenceLinks(reference, false).then((result) => {
            if (isMounted) {
                setLink(result);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [reference]);

    return (
        <>
            {link && link.linkParams ? (
                <a
                    href={
                        "/view/" +
                        link.linkParams.moduleId +
                        "/" +
                        link.linkParams.viewId +
                        "?id=" +
                        link.linkParams.id
                    }
                >
                    {props.children ? props.children : link.label}
                </a>
            ) : null}
            {link && !link.linkParams ? (
                <>{props.children ? props.children : link.label}</>
            ) : null}
            {!link && props.children ? (
                <>{props.children}</>
            ) : null}
        </>
    );
});
