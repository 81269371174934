import {inject} from "aurelia-framework";
import {Executor} from "../executor";
import {FlashService} from "../../../../flash/flash-service";

@inject(
    FlashService
)
export class NotWorkflowExecutor extends Executor {
    flash;

    constructor(
        flash
    ) {
        super();

        this.flash = flash;
    }

    match(action, context) {
        return 'workflow' !== action.type;
    }

    execute(action, cartContext) {
        const {
            selectedParticipants,
            selectedItems
        } = cartContext;

        const context = {
            id: selectedItems,
            modelId: 'order/order-item'
        };

        if (!context.id.length) {
            context.id = selectedParticipants;
            context.modelId = 'tourism/journey-participant';
        }

        if (0 === context.id.length) {
            return this.flash.error('Bitte erst die Posten auswählen');
        }

        return Promise.resolve(context);
    }
}
