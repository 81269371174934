import * as _ from "lodash";
import moment from "moment";

export class FromDateBucketsGenerator {
    buildBuckets(
        items,
        orderParticipants,
        withoutParticipant,
        journeys,
        defaultSortableOptions,
        order
    ) {
        // detect buckets
        let buckets = new Map();

        for (let item of items.values()) {

            //Important to clone here, so we keep original items
            item = _.cloneDeep(item);
            //item.id = [item.id];

            let bucketKey = item?.fromDate ? moment.utc(item.fromDate).format('YYYY-MM-DD') : 'without';

            if (item.parents?.[0]) {
                let parentItem = items.get(item.parents?.[0].id);
                bucketKey = parentItem?.fromDate ? moment.utc(parentItem.fromDate).format('YYYY-MM-DD') : 'without'
            }

            item.bucketKey = bucketKey;

            if (!buckets.has(bucketKey)) {
                buckets.set(bucketKey, [item]);
            } else {
                buckets.get(bucketKey).push(item);
            }
        }

        let num = 1;

        return Array.from(buckets.values()).sort((a, b) => b[0].bucketKey - a[0].bucketKey).map(items => {

            //Important that sorting happens after merging
            items = items.sort((a, b) => {

                if (a.sort == b.sort) {

                    return (a.fromDate || 'ZZZZZ').localeCompare(b.fromDate);
                }

                return a.sort - b.sort;
            }).map(item => {

                if (item.product && 'tourism/journey' === item.product.modelId) {
                    if (!journeys.has(item.product.id)) {
                        journeys.set(item.product.id, item.product);
                    }
                }

                return item;
            });

            let bucketTitle = items[0].bucketKey;

            if (bucketTitle === 'without') {
                bucketTitle = 'Ohne Tageszuordnung'
            } else {
                bucketTitle = moment.utc(bucketTitle).format('dddd, DD.MM.YYYY');
            }

            let dayKey = null;

            if (order.offerStartDate && items[0].bucketKey !== 'without') {
                console.log('TEST', order.offerStartDate, items[0].bucketKey);
                dayKey = moment.utc(items[0].bucketKey).diff(moment(order.offerStartDate).startOf('day'), 'days') + 1;
            }

            return {
                collapsed: false,
                bucketKey: items[0].bucketKey,
                bucketDay: dayKey,
                bucketTitle: bucketTitle,
                items: items,
                participants: [],
                sortableOptions: _.defaults(
                    {
                        group: {
                            name: 'bucket-' + num,
                            put: ['without-participant'],
                        }
                    },
                    defaultSortableOptions
                ),
            };
        });
    }
}
