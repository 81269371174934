import {bindable, inject} from 'aurelia-framework';
import {UserClient} from "../../../../api/user-client";

@inject(UserClient)
export class PickupInformation {
    @bindable item;

    constructor(userClient) {
        this.userClient = userClient;
        this.isPickupForDbtov = this.userClient.getUser().organization.settings.tourismDbtovOrganization?.isPickup ?? false;
    }

    isPickupItem() {
        if (null === this.item) {
            return false;
        }

        const isDbtov = this.item.provider === 'tourism-dbtov/cruise'
            || this.item.provider === 'tourism-dbtov/rail-and-fly';

        // we need to see the data, that was set earlier, even if isPickup in org is not active
        const isActiveForDbtov = (isDbtov && (this.isPickupForDbtov || (this.item.data?.isPickup ?? false)));

        return this.item && (
            isActiveForDbtov
            || (this.item.data?.isPickup ?? false)
            || (this.item.product?.isPickup ?? false)
        );
    }
}
