import {inject, bindable} from "aurelia-framework";
import {Client} from "../../../api/client";
import {ConditionMatcher} from "../../../condition-builder/condition-matcher.js";
import {ModuleConfigClient} from "../../../api/module-config-client";

@inject(
    Client,
    ConditionMatcher,
    ModuleConfigClient
)
export class OfferBackupDropdown {
    @bindable order;
    @bindable cart;

    _actions;

    constructor(
        client,
        conditionMatcher,
        moduleConfig
    ) {
        this.client = client;
        this.conditionMatcher = conditionMatcher;
        this.moduleConfig = moduleConfig;
    }

    orderChanged()
    {
        this.client.get(
            `order/cart-view/order/${this.order.id}/offer-backups/actions`
        ).then(
            async actions => {
                this._actions = actions.map(
                    payload => {
                        const action = payload['action'];

                        return action;
                    }
                );

                this._backupOfferWorkflow = await this.moduleConfig.getWorkflow('order/backup_offer');
            }
        );
    }
}
