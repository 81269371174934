import { Container } from 'aurelia-framework';
import { FlashService } from '../flash/flash-service';
export const getFlashInstance = () => {
  const flash = Container.instance.get(FlashService);
  if (!flash) {
    return undefined;
  }
  return flash;
};

export function useFlash() {
  return getFlashInstance();
}
