import {bindable} from 'aurelia-framework';
import moment from "moment";

export class ParticipantInformation {
    @bindable participant;
    @bindable cart;

    typeIdentifier;
    typeTitle;
    birthdayDuringTrip = null;

    bind() {
        this.participantType();
    }

    participantType() {

        let birthdate = this.participant?.customer?.birthdate ?? null;

        this.typeIdentifier = null;
        this.typeTitle = null;

        if (birthdate === null) {
            return null;
        }

        birthdate = moment(birthdate);

        if (!this.cart.order.fromDate || !this.cart.order.toDate) {
            return;
        }

        let orderFrom = moment(this.cart.order.fromDate);
        let orderTo = moment(this.cart.order.toDate);

        let orderFromDays = orderFrom.month() * 100 + orderFrom.date();
        let orderToDays = orderTo.month() * 100 + orderTo.date();
        let birtdateDays = birthdate.month() * 100 + birthdate.date();

        if (orderFromDays <= birtdateDays && birtdateDays <= orderToDays) {
            this.birthdayDuringTrip = birthdate.format('DD.MM');
        }

        let years = orderTo.diff(birthdate, 'years');

        if (years < 2) {
            this.typeIdentifier = 'INF';
            this.typeTitle = 'Kleinkind, ' + years + ' Jahre';
        } else if (years < 18) {
            this.typeIdentifier = 'CH ' + years;
            this.typeTitle = 'Kind, ' + years + ' Jahre';
        } else {
            this.typeIdentifier = 'AD ' + years;
            this.typeTitle = 'Erwachsen, ' + years + ' Jahre';
        }
    }

}
