import React from 'react';
import { ActionButton } from '../../../action/react/action-button';
const WorkloadButtons = ({ order, services }) => {
  return (
    <div className="btn-group">
      <ActionButton
        config={{
          type: 'wizard',
          label: 'Teilnehmerliste erfassen',
        }}
        style={{ background: 'rgb(92, 184, 92)', color: 'white' }}
        context={{
          steps: ['tourism-order/upload-file', 'tourism-order/chose-services'],
          successMessage: 'Teilnehmer erfolgreich erfasst',
          updateModelIds: [
            'order/order',
            'tourism-order/b2b-offer-participant',
          ],
          bindValues: [],
          previewPrevious: false,
          label:
            'tourism-order.modelExtensions.b2b-offer-participant.display.actions.zero',
          contextObjectRef: {
            id: order.id,
            modelId: 'order/order',
          },
          selectedItems: [],
        }}
        size={'xs'}
        onSubmitted={()=>{window.location.reload()}}
      />
    </div>
  );
};

export default WorkloadButtons;
