import {customElement, bindable, inject, containerless} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {PreviewModal} from "./preview-modal";

@customElement('sio-file-preview')
@inject(
    DialogService
)
@containerless()
export class Preview
{
    @bindable
    file;

    constructor(dialog)
    {
        this.dialog = dialog;
    }

    openPreview()
    {
        this.dialog.open(
            {
                viewModel: PreviewModal,
                model: {
                    file: this.file
                }
            }
        );
    }
}