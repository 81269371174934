export class ExecutorRegistry {
    constructor()
    {
        this.executors = [];
    }

    registerExecutor(executor)
    {
        this.executors = [executor].concat(this.executors);
    }

    execute(action, context)
    {
        for (let executor of this.executors) {
            if (executor.match(action, context)) {
                return executor.execute(action, context);
            }
        }

        throw new Error("Failed to find matching action executor");
    }

    afterExecutionCallback(action, context, result)
    {
        for (let executor of this.executors) {
            if (executor.match(action, context)) {
                return executor.afterExecutionCallback(action, context, result);
            }
        }

        throw new Error("Failed to find matching action executor");
    }
}
