import {bindingMode, inject} from 'aurelia-framework';
import {PermissionClient} from "../api/permission-client";

function hasPermission(newValue) {

    if (!this.conditions || this.conditions.length == 0) {
        this.choiceMethod(true);
        return;
    }

    this.permissionClient.matchesPermissionCondition(newValue, this.conditions).then(
        hasRole => {
            this.choiceMethod(hasRole);
        },
        () => {
            this.choiceMethod(false);
        }
    );
}

/**
 * Checks if user has permission
 */
@inject(PermissionClient)
export class PermissionConditionBindingBehavior {

    //Taken from aurelia throttle binding behavior

    constructor(client) {
        this.permissionClient = client;
    }

    bind(binding, source, conditions) {
        // determine which method to throttle.
        let methodToThrottle = 'updateTarget'; // one-way bindings or interpolation bindings
        if (binding.callSource) {
            methodToThrottle = 'callSource';     // listener and call bindings
        } else if (binding.updateSource && binding.mode === bindingMode.twoWay) {
            methodToThrottle = 'updateSource';   // two-way bindings
        }

        // stash the original method and it's name.
        // note: a generic name like "originalMethod" is not used to avoid collisions
        // with other binding behavior types.
        binding.choiceMethod = binding[methodToThrottle];
        binding.choiceMethod.originalName = methodToThrottle;

        binding.permissionClient = this.permissionClient;
        binding.conditions = conditions;

        // replace the original method with the throttling version.
        binding[methodToThrottle] = hasPermission;
    }

    unbind(binding, source) {
        // restore the state of the binding.
        let methodToRestore = binding.choiceMethod.originalName;
        binding[methodToRestore] = binding.choiceMethod;

        binding.permissionClient = null;
        binding.conditions = null;
    }
}
