

export class ConvertProviderValueConverter
{

    toView(str)
    {
        if (!str) {
            return '';
        }

        return str.replace('/', '-');
    }

}
