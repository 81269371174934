import React from 'react';
import useLocalStorage from './useLocalStorage';

const usePin = () => {
  const [pinned, setPinned] = useLocalStorage('sio-pinned', []);
  const [history, setHistory] = useLocalStorage('sio-router-history', []);

  const isPinned = pinned.some((pin) => pin?.href === history[0]?.href);

  const togglePin = () => {
    if (isPinned) {
      const newPins = pinned.filter((pin) => pin?.href !== history[0]?.href);
      setPinned(newPins);
    } else {
      const newPins = [history[0], ...(pinned || [])];
      setPinned(newPins);
    }
  };

  return [isPinned, togglePin, pinned];
};

export default usePin;
