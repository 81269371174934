import React from 'react';
import {
  getColumnProperties,
  updateFieldValue,
  inputValueParser,
} from '../functions/functions';
import ReactSelect from 'react-select';
import { selectStyles } from '../static/selectStyles';
import { DateRangePicker } from '../../../../node_modules/rsuite/esm/index';
import { InputNumber } from 'rsuite';
const FormField = ({ row, column, columnsConfig, setFormData }) => {
  const { key, label, dynamicItemId, showRoomNumberField } = column;
  const {
    type,
    subType,
    choices,
    required,
    isMulti,
    subFields,
    onPostChange,
  } = getColumnProperties(key, columnsConfig);

  if (type === 'nested') {
    const subFieldData = subFields.find((item) => item.key == dynamicItemId);
    if (!subFieldData?.key) {
      return <div>Undefined FormField</div>;
    }
    if (subFieldData.type == 'input') {
      return (
        <input
          type={subFieldData.subType}
          value={inputValueParser(
            row[key],
            type,
            subFieldData?.key,
            subFieldData.subType
          )}
          className="form-control"
          onChange={(e) => {
            updateFieldValue(
              e.target.value,
              row.id,
              key,
              setFormData,
              dynamicItemId
            );
          }}
        />
      );
    }
    if (subFieldData.type === 'choice') {
      return (
        <ReactSelect
          isMulti={false}
          name={subFieldData.key}
          value={subFieldData.choices.find((val) => val.value == row[key])}
          options={subFieldData.choices}
          styles={selectStyles}
          onChange={(e) => {
            updateFieldValue(e.value, row.id, key, setFormData, dynamicItemId);
            if (typeof onPostChange == 'function') {
              onPostChange(e.value, row.id, setFormData);
            }
          }}
          className={'react-select'}
          closeMenuOnSelect={true}
          openMenuOnFocus={true}
        />
      );
    }
  }

  if (type == 'input') {
    if (dynamicItemId && subType == 'checkbox') {
      return (
        <input
          id={`order_item${row[key]}id:${dynamicItemId}`}
          type={subType}
          checked={row[key]?.some((item) => item.id == dynamicItemId)}
          className="form-control"
          onChange={(e) => {
            const currentRowItems = row[key] || [];
            const newOrderItems = e.currentTarget.checked
              ? [...currentRowItems, { id: dynamicItemId }]
              : currentRowItems.filter((item) => item.id !== dynamicItemId);
            updateFieldValue(newOrderItems, row.id, key, setFormData);
          }}
        />
      );
    }
    if (dynamicItemId && subType == 'choice') {
      const value = row[key] && row[key][dynamicItemId];
      return (
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          <div style={{ flex: 10 }}>
            <ReactSelect
              isMulti={false}
              name={`order_item${row[key]}id:${dynamicItemId}`}
              value={column?.choices.find((val) => val.value == value)}
              options={column?.choices}
              styles={selectStyles}
              onChange={(e) => {
                updateFieldValue(
                  e.value,
                  row.id,
                  key,
                  setFormData,
                  dynamicItemId
                );
                if (typeof onPostChange == 'function') {
                  onPostChange(e.value, row.id, setFormData);
                }
              }}
              className={'react-select'}
              closeMenuOnSelect={true}
              openMenuOnFocus={true}
            />
          </div>
          {showRoomNumberField && (
            <input
              type="number"
              className="form-control"
              style={{ flex: 3, height: '30px' }}
              onChange={(e) => {
                updateFieldValue(
                  Number(e.target.value),
                  row.id,
                  key,
                  setFormData,
                  dynamicItemId,
                  'showRoomNumberField'
                );
                if (typeof onPostChange == 'function') {
                  onPostChange(e.value, row.id, setFormData);
                }
              }}
              value={row?.showRoomNumberField[dynamicItemId.trim()]}
            />
          )}
        </div>
      );
    }
    if (subType == 'checkbox') {
      return (
        <input
          type={subType}
          checked={row[key]}
          className="form-control"
          onChange={(e) => {
            updateFieldValue(e.currentTarget.checked, row.id, key, setFormData);
            if (typeof onPostChange == 'function') {
              onPostChange(e.currentTarget.checked, row.id, setFormData);
            }
          }}
        />
      );
    }

    if (subType == 'percentage') {
      return (
        <InputNumber
          align="right"
          size="sm"
          value={inputValueParser(row[key], subType, key)}
          step={0.1}
          style={{ width: '100%' }}
          lang="de"
          onChange={(value, event) => {
            updateFieldValue(value, row.id, key, setFormData);
            if (typeof onPostChange == 'function') {
              onPostChange(value, row.id, setFormData);
            }
          }}
          postfix="%"
        />
      );
    }

    if (subType == 'money') {
        return (
          <InputNumber
            align="right"
            size="sm"
            value={inputValueParser(row[key], subType, key)}
            step={1}
            style={{ width: '100%' }}
            lang="de"
            onChange={(value, event) => {
              updateFieldValue(value, row.id, key, setFormData);
              if (typeof onPostChange == 'function') {
                onPostChange(value, row.id, setFormData);
              }
            }}
            postfix="€"
          />
        );
      }

    return (
      <input
        type={subType}
        value={inputValueParser(row[key], subType, key)}
        className="form-control"
        onChange={(e) => {
          updateFieldValue(e.target.value, row.id, key, setFormData);
          if (typeof onPostChange == 'function') {
            onPostChange(e.value, row.id, setFormData);
          }
        }}
      />
    );
  }
  if (type == 'choice') {
    return (
      <ReactSelect
        isMulti={isMulti}
        name={key}
        value={choices.find((val) => val.value == row[key])}
        options={choices}
        styles={selectStyles}
        onChange={(e) => {
          updateFieldValue(e.value, row.id, key, setFormData);
          if (typeof onPostChange == 'function') {
            onPostChange(e.value, row.id, setFormData);
          }
        }}
        className={'react-select'}
        closeMenuOnSelect={true}
        openMenuOnFocus={true}
      />
    );
  }
  return <div>Undefined FormField</div>;
};

export default FormField;
