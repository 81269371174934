import {bindable, Container, customElement, inject, noView} from 'aurelia-framework';
import ReactDOM from "react-dom";
import React from "react";
import DebugPopover from "./debug-react";

@customElement('sio-debug')
@noView()
@inject(Element)
export class Debug {

    @bindable content;

    constructor(element) {
        this.element = element;
    }

    detached() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    async bind() {
        this.render();
    }

    render() {
        ReactDOM.render(
            <DebugPopover content={this.content}></DebugPopover>,
            this.element
        );
    }
}
